import './recipes.css'
import Container from 'react-bootstrap/Container'

export default function Recipes(){
    return(
        <div>
            <h1 id="recipe-title">Recipes</h1>
            <Container>
                <p>
                    This page is under construction. We would like to know how you
                    eat your microgreens. What are your favorite recipes? Please send
                    us an email to <a href="mailto: support@holisticgreenthings.com">support@holisticgreenthings.com</a>
                </p>
            </Container>
           
        </div>
    )
}
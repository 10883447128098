import './commercial.css'
import Container from 'react-bootstrap/Container'


export default function Commercial(){
    return(
        <div id="commercial-div">
        <h1 id="commercial-title">Restaurants and Stores</h1>
        <Container>
            <p>
                If you are a store or a restaurant and looking for a quality product, you have came
                to the right place. We grow according to your needs so you get the freshest microgreens. 
                We are hand harvesting and packing each order, this allows us a hands on check as each 
                order is packaged. 
            </p>

            
            <p>
                We grow our microgreens so they have zero soil contact and don't risk contamination
                from fertilizers or things like E. Coli or Listeria. They are grown in a controlled climate
                and we are able to make all varieties available all year long. This also allows us 
                to be free of pest and we don't use any kind of pestaside. 
            </p>
            <p>
                All of our seeds are non-GMO. So you know you are getting the purest form of nutrition
                from these little guys. 
            </p>
            <p>
                We also know how important customer service is. Today many places have forgotten 
                the customer service aspect of any business. We want to help you and try to make things for 
                your business easier. We understand that restaurants have special needs for delivery 
                times so we will not be delivering durning your lunch or dinner rush. We also understand there
                are ebbs and flows in business. 
            </p>
            <p>
                If we are not currently growing a variety you would like for your restaurant or store
                please reach out to us because we are always looking to add new varieties to our list. 
            </p>
        </Container>

        
    </div>
    )
    
}
import Table from 'react-bootstrap/Table'
import './events.css'

export default function March(){
    return(
        <>
         <h1 className="march-title">March</h1>
        
         <Table responsive="sm" bordered>
            <thead>
                <tr>
                    <th>Sunday</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesay</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                   
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td> 
                       
                    
                    </td>
                    <td>
                        <p></p>
                        
                    </td>
                    <td>
                    <p>1</p>
                        <h5>SaddleBrooke Farmers Market</h5>
                        <span>9:00 A.M. - 1:00 P.M.</span>
                        
                    </td>
                    <td>
                    <p>2</p>
                        <h5>St. Phillips Farmers Market</h5>
                        <span>8:30 A.M. - 3:00 P.M.</span>
                    </td>

                </tr>
                <tr>
                    <td>
                        <p>3</p>

                    </td>
                    <td>
                        <p>4</p>
                        
                    </td>
                    <td>
                        <p>5</p>
                        <h5>Tucson Estates Farmers Market</h5>
                        <span>10:00 A.M. - 2:00 P.M.</span>

                    </td>
                    <td>
                        <p>6</p>
                        <h5>Farm Fresh Farmers Market at Palomino Plaza</h5>
                        <span>10:00 A.M. - 1:00 P.M. </span>
                    </td>
                    <td>
                        <p>7</p>
                    </td>
                    <td>
                        <p>8</p>
                    </td>
                    <td>
                        <p>9</p>
                        <h5>St. Phillips Farmers Market</h5>
                        <span>8:30 A.M. - 3:00 P.M.</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>10</p>

                    </td>
                    <td>
                        <p>11</p>
                       
                    </td>
                    <td>
                        <p>12</p>
                        <h5>Tucson Estates Farmers Market</h5>
                        <span>10:00 A.M. - 2:00 P.M.</span>

                    </td>
                    <td>
                        <p>13</p>
                    </td>
                    <td>
                        <p>14</p>
                    </td>
                    <td>
                        <p>15</p>
                        <h5>SaddleBrooke Farmers Market</h5>
                        <span>9:00 A.M. - 1:00 P.M.</span>

                    </td>
                    <td>
                        <p>16</p>
                        <h5>St. Phillips Farmers Market</h5>
                        <span>8:30 A.M. - 3:00 P.M.</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>17</p>

                    </td>
                    <td>
                        <p>18</p>
                       
                    </td>
                    <td>
                        <p>19</p>
                        <h5>Tucson Estates Farmers Market</h5>
                        <span>10:00 A.M. - 2:00 P.M.</span>

                    </td>
                    <td>
                        <p>20</p>
                    </td>
                    <td>
                        <p>21</p>
                    </td>
                    <td>
                        <p>22</p>
                    </td>
                    <td>
                        <p>23</p>
                        <h5>St. Phillips Farmers Market</h5>
                        <span>8:30 A.M. - 3:00 P.M.</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>24</p>

                    </td>
                    <td>
                        <p>25</p>
                       
                    </td>
                    <td>
                        <p>26</p>
                        <h5>Tucson Estates Farmers Market</h5>
                        <span>10:00 A.M. - 2:00 P.M.</span>

                    </td>
                    <td>
                        <p>27</p>
                    </td>
                    <td>
                        <p>28</p>
                    </td>
                    <td>
                        <p>29</p>
                    </td>
                    <td>
                        <p>30</p>
                        
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>31</p>
                    </td>
                </tr>
            </tbody>
        </Table>
     
        </>
    )
}
import './microgreens.css'
import Container from 'react-bootstrap/Container'
import {useEffect} from 'react'

export default function Microgreens() {
    useEffect(() => {
        window.location.href= 'https://holistic-green-things.square.site/'
    })

    return(
        <div>
           <h1 id="microgreens-title"> Microgreens page</h1>
           <Container>
            <p>We are working on the website and building a custom shopping cart 
                and intergrating payment processing. You will be automatically redicted to complete
                your purchase through Square. If you are not please click this 
                <a href="https://holistic-green-things.square.site/" target="_blank" id="shop-link"> link</a>
                 to order through our square shopping.  
            </p>
           </Container>
           
        </div>
    )
}
import './home.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Toast from '../images/avacodoTstWeb.jpg'
import Image from 'react-bootstrap/Image'
import Pea from '..//images/peaSmall.jpg'
import Sunflower from '..//images/sunflowerSmall.jpg'
import Crab from '..//images/crabSaladWeb.jpg'


function Home(){
    return(
        <div>
           {/* <Row id="main-page">
                <row lg="4">
                    <Image src={Toast} class="home-images"/>
                    <Image src={Crab} class="home-images"/>
                    <Image src={Pea} class="home-images"/>
                    <Image src={Sunflower} class="home-images"/>
                </row>
            </Row>
            */}
        <h1 id="home-title">Welcome to Holistic Green Things.</h1>
        <Container>
        <p className="home-paragraph">
            Holistic Green Things is an urban farm where we are growing microgreens and wheatgrass.
            We are happy to service our local neighboors, restaurants and stores. 
        </p>
        <p>
            We grow a large variety of microgreens to offer many different flavors, textures and colors to 
            tempt and tanalize your pallet. Not only do you please your taste buds, you are adding intense
            nutrition to your diet. 
        </p>
        <p>
            The greatest thing about microgreens is that you don't have to change your diet eat them. 
            You just add them to your diet. This allows you to add much needed nutrients that your body 
            needs to function properly. 
        </p>
        <p className="home-paragraph">
            If you would like to try and learn more about microgreens, 
            we are proud to say that we are at St. Phillips Farmers Market every weekend. Come check us out and 
            have your taste buds tantalized and learn about the world of microgreens. 
        </p> 
        <p className="home-paragraph">
            You can also check out our <a href="https://facebook.com/HolisticGreenThings>" target="blank" rel="noreferrer noopener">Facebook Page </a>
            for any new updates. 
        </p>           
        </Container>
       
       
        </div>  
    )
}

export default Home
import './about.css'
import Container from 'react-bootstrap/Container'
import Footer from './Footer'


export default function About() {
    return(
        <div>
            <h1 id="about-header">About Us</h1>
            <Container>
                <p>
                    In today's world we are seeing our farm lands being stripped
                    of any nutrients that are needed to grow healthy strong plants.
                    We are also seeing many commercial farmers turn to genetically 
                    modified seeds because being able to grow strong healthy plants 
                    naturally is becomming harder and harder. 
                    Seeing this really makes it very hard to get the nutrition that we 
                    need to live healthy and strong lives. 
                    We have to ask ourselves how do we solve this problem? How do we 
                    make farming more sustainable? We may not be able to solve this problem
                    all by ourselves here at Holistic Green Things, but we would like
                    to be part of the solution. We believe that smaller farms and localized
                    growing would help bring food to our tables that have nutrition.
                    We have started our journey as an urban farm. We have started out growing
                    microgreens with organic practices. This way we can bring some of the 
                    most nutrious food to your table. 
                </p>

            </Container>
          
        </div>
    )
}
import Container from 'react-bootstrap/Container'
import './delivery.css'
import Footer from './Footer'

export default function Delivery(){
    return(
        <div>
            
            <Container>
            <h1 id="pickup-title">Pick-up</h1>
                <p>
                    We know that there are times when you come to the market and your 
                    favorite microgreens have already been sold out. We know how devistating 
                    this can be to your taste buds, in effort to keep your taste buds satisfied, 
                    pre-order for pick-up on the website. We will then take your favorites out of circulation
                    and you just need to pick-up and go. 
                </p>
                <p>
                    Just make sure that you have placed your order by 5:00 P.M. the day before we
                    are at your market.
                </p>
                <p>
                    For those of you who just can't get to the market checkout the topic below. 
                </p>
                    
                
            <h1 id="delivery-title">Our Delivery Program</h1>
                <p>We know that in today's world we are having a harder time getting to the grocery 
                store much less to make it to a farmer's market to get healthier food. To help solve this 
                problem we have implemented a home deliver program.
                </p>
                <p>
                    Our delivery service does have a $5.00 delivery charge. However, you can get free delivery
                    if you order a minimum order of $20.00 or sign up for the subscription program. These 
                    orders must be placed by Friday 5:00 P.M. If we don't have your order by
                    this time your order will be delivered the following monday. 
                    At this time we are only doing deliveries on Mondays within the Tucson metro area.  

                </p>
                <p>
                    We do suggest that if you do take advantage our delivery service, please leave a cooler by the
                    door to keep your microgreens fresh. These little guys are parishable and need to be kept cool. 
                    Adding a gel pack or ice to the cooler would also be benifical. 
                </p>

            <h1 id="subscription-title">Subscriptions</h1>    
                <p>
                    Subscriptions are an easy way to have a no fuss supply of your 
                    favorite microgreens being delivered on a regular basis. This allows
                    us to grow specifically for you and you don't have to worry if what you want
                    is already sold out for pick or delivery. 
                </p>
                
            </Container>
          
        </div>
    )
}
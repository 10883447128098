import './contact.css'
import Container from 'react-bootstrap/Container'
import Footer from './Footer'

export default function Contact(){
    return(
        <div>
            <h1 id="contact-title"> Contact page</h1>
            <Container>
                <p className="contact-paragraph">
                    If you find the need to reach out to us you can call,
                    send an email or send us a private message through Facebook. 
                    We are here to answer your questions or get feedback from you. 
                </p>
                <p className="contact-paragraph">
                    If you are a restaurant or a store and would like to get more information
                    please feel free to reach out to us. 
    
                </p>
                <p className="contact-paragraph">Call us at: <a href="tel: 520-486-1753" title="Call us"> 520-486-1753</a></p>
                <p className="contact-paragraph">Email:<a href="mailto: support@holisticgreenthings.com" id="email-link"> support@holisticgreenthings.com</a></p>
                <p className="contact-Paragraph">Our<a href="www.facebook.com/HolisticGreenThings"> Facebook</a> page</p>
            </Container>
            < Footer />
           
        </div>
    )
}
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About from './pages/About'
import Contact from './pages/Contact'
import Microgreens from './pages/Microgreens'
import Recipes from './pages/Recipes'
import Home from './pages/Home'
import Delivery from './pages/Delivery'
import { Navigation } from './pages/Navigation'
import Commercial from './pages/Commercial'
import { Events } from './pages/Events'
import Footer from "./pages/Footer"
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <main>
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="microgreens" element={<Microgreens />} />
          <Route path="recipes" element={<Recipes />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="commercial" element={<Commercial />} />
          <Route path="events" element={<Events />} />
          {/*<Route path="cart" element={<Cart />} />*/}
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
